@use '../bootstrap/bootstrap.scss';
@use './variables.scss';

@mixin overeno-style {
    background-color: variables.$overeno-bg;
    color: variables.$overeno-fg;
}

.alert-overeno {
    @extend .alert-primary;
    @include overeno-style;

    p:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
    }
}
