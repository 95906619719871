@use './../bootstrap/variables' as *;

main,
footer {
    overflow: hidden;
}

@media (min-width: 1450px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1400px;
    }
}

figure {
    margin: 0;
}

.mw-100-img img {
    max-width: 100%;
}

small {
    font-size: 11px;
}

p a {
    text-decoration: underline;
}

.button-more-with {
    width: 100%;

    @media (min-width: 768px) {
        width: 460px;
    }
}

.mb-n-0 * {
    margin-bottom: 0;
}

.mb-n-20 * {
    margin-bottom: 10px;

    @media (min-width: 768px) {
        margin-bottom: 20px;
    }
}

.mb-last-0 > *:last-of-type {
    margin-bottom: 0;
}

.lang {
    text-transform: uppercase;

    &.active {
        display: none;
    }
}

.font-400,
.font-400 * {
    font-family: PublicSans-400, sans-serif;
    font-weight: 400;
}

.font-600,
.font-600 * {
    font-family: PublicSans-600, sans-serif;
    font-weight: 600;
}

.font-700,
.font-700 * {
    font-family: PublicSans-700, sans-serif;
    font-weight: 700;
}

strong,
.font-800,
.font-800 * {
    font-family: PublicSans-800, sans-serif;
    font-weight: 800;
}

.w-100-img img {
    width: 100%;
}

.wm-100-img img {
    max-width: 100%;
}

.btn-xxl-width {
    min-width: 100%;

    @media (min-width: 567px) {
        min-width: 385px;
    }
}

.text-underline,
.text-underline * {
    text-decoration: underline;
}

.link-underline a {
    text-decoration: underline;
}

table {
    font-size: 0.95rem;
    width: 100%;

    tr {
        border-bottom: 1px solid $info;
    }

    td {
        padding: 0.7rem 0;
    }
}

.response-img {
    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

.w-xxl-auto {
    @media (min-width: 1400px) {
        width: auto !important;
    }
}

.linear-gradient1 {
    background: linear-gradient(90deg, #00252e 41.75%, #006a82 87.83%);
}

.pt-1px {
    padding-top: 1px;
}

.small-text,
.small-text * {
    font-size: 11px;
}

.z-index-2,
.z-index-2 * {
    position: relative;
    z-index: 2;
}

.latest-articles-w {
    @media (min-width: 567px) {
        width: 385px;
    }
}

.win-item {
    border-radius: 10px;
    box-shadow: 2px 2px 10px 2px rgba(47, 44, 44, 0.15);
    padding: 25px 30px;

    @media (min-width: 992px) {
        padding: 40px 50px;
    }

    p {
        line-height: 30px;
        margin-bottom: 0;
    }

    h3 {
        line-height: 1.2;
        margin: 10px auto;
    }
}

.box-shadow {
    box-shadow: 2px 2px 10px 2px rgba(47, 44, 44, 0.15);
}

.external-link-block a,
.external-link {
    position: relative;

    &::after {
        content: url('./../img/external-link.svg');
        display: inline-block;
        padding: 0 4px;
        position: relative;
        transform: translate(1px, -4px);
    }
}

.user-address > div {
    display: inline-block;
}

.text-icon-arrow {
    strong {
        position: relative;

        &::after {
            bottom: 11px;
            content: url('./../img/text-icon-arrow.svg');
            left: 0;
            position: absolute;
            right: -37px;
            text-align: center;
        }
    }
}

.text-icon-circle {
    strong {
        position: relative;

        &::after {
            bottom: -14px;
            content: url('./../img/text-icon-circle.svg');
            left: -3px;
            position: absolute;
            right: 0;
            text-align: center;
            z-index: -1;
        }
    }
}

.text-icon-underline {
    strong {
        position: relative;

        &::after {
            bottom: -21px;
            content: url('./../img/text-icon-underline.svg');
            left: -33px;
            position: absolute;
            right: 0;
            text-align: center;
        }
    }
}
