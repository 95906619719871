@use "sass:math";

@font-face {
    font-family: 'PublicSans-400';
    src: url('../fonts/PublicSans-400.ttf');
}

@font-face {
    font-family: 'PublicSans-600';
    src: url('../fonts/PublicSans-600.ttf');
}

@font-face {
    font-family: 'PublicSans-700';
    src: url('../fonts/PublicSans-700.ttf');
}

@font-face {
    font-family: 'PublicSans-800';
    src: url('../fonts/PublicSans-800.ttf');
}

// Color system
// scss-docs-start color-variables
$secondary: #00ffc2;
$primary: #1492ff;
$warning: #ffc200;
$dark: #404040;
$title: #616365;
$border: #afafaf;
$light: #dbdbdb;
$info: #fafafa;
$white: #fff;
$black: #000;
$danger: #e23d3d;
$success: #21eb21;

// scss-docs-start theme-colors-map
$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'info': $info,
    'dark': $dark,
    'white': $white,
    'light': $light,
    'border': $border,
    'title': $title,
    'danger': $danger,
    'black': $black,
);

// Spacing
// scss-docs-start spacer-variables-maps
$spacer: 1rem;
$spacers: (
    0: 0,
    1: 0.25rem,
    // 4
    2: 0.5rem,
    // 8
    3: 1rem,
    // 16
    4: $spacer * 1.5,
    // 24
    5: $spacer * 3,
    // 48
    20: 20px,
    30: 30px,
    40: 40px,
    50: 50px,
    60: 60px,
    70: 70px,
    80: 80px,
    90: 90px,
    100: 100px,
    120: 120px,
);

@function space($space) {
    @return map-get($spacers, $space);
}

// Typography
$font-family-base: PublicSans-400, sans-serif;
$font-size-base: 1.125rem;
$headings-line-height: 1.5;
$headings-font-weight: 700;
$headings-font-family: PublicSans-800, sans-serif;
$headings-margin-bottom: 0;
$h1-font-size: $font-size-base * 2.666; //48
$h2-font-size: $font-size-base * 2.333; //42
$h3-font-size: $font-size-base * 1.777; //32
$h4-font-size: $font-size-base * 1.111; // 20
$h5-font-size: $font-size-base;// 18
$h6-font-size: $font-size-base * 0.888; // 16

// Body
// Settings for the `<body>` element.
$body-color: $dark;
$body-bg: $white;

// Style anchor elements.
$link-color: $dark;
$link-decoration: none;

// scss-docs-start font-sizes
$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size,
);

// scss-docs-start display-headings
$display-font-sizes: (
    1: 48px,
    2: 42px,
    3: 32px,
    4: 20px,
    5: 18px,
    6: 16px,
    7: 14px,
    8: 12px,
);

// Buttons
// scss-docs-start btn-variables
$btn-color: $dark;
$btn-padding-y: 1.69rem;
$btn-padding-x: 2rem;
$input-btn-font-family: PublicSans-700, sans-serif;
$btn-font-family: PublicSans-700, sans-serif;
$btn-font-weight: 700;
$btn-font-size: 30px;
$btn-line-height: 1;

$btn-padding-y-sm: 1.16rem;
$btn-padding-x-sm: 2rem;
$btn-font-size-sm: 1.15rem;

$btn-padding-y-lg: 1.45rem;
$btn-padding-x-lg: 2rem;
$btn-font-size-lg: 24px;

$btn-border-width: 1px;
$btn-border-radius: 5px;
$btn-border-radius-sm: 5px;
$btn-border-radius-lg: 5px;

// Grid breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$input-padding-y: 0.75rem;
$input-padding-x: 1rem;
$input-color: $primary;
$input-border-color: $border;
$input-border-width: 1px;
$input-box-shadow: none;
$input-border-radius: 8px;
$input-focus-width: 1px;
$input-focus-border-color: $primary;
$input-focus-box-shadow: none;
$input-placeholder-color: $title;

$nav-link-padding-y: 1rem;
$nav-link-padding-x: 4.125rem;
$nav-link-font-size: 1rem;
$nav-link-color: $primary;
$nav-link-hover-color: $primary;

$nav-tabs-border-color: $primary;
$nav-tabs-border-width: 1px;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: $primary;
$nav-tabs-link-active-color: $primary;
$nav-tabs-link-active-bg: $info;
$nav-tabs-link-active-border-color: $primary;

// scss-docs-start border-radius-variables
$border-radius-lg: 10px;
