@use 'variable' as *;

ul.messagelist {
    background-color: $debug;
    font-size: 160%;
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 5rem;
    padding-left: 0;

    li {
        padding: 0.6em 1.6em;

        &.debug {
            color: $black;
        }

        &.info {
            background-color: $info;
        }

        &.warning {
            background-color: $warning;
        }

        &.success {
            background-color: $success;
        }

        &.error {
            background-color: $error;
            color: $white;
        }

        > p {
            margin-top: 5rem;
        }
    }
}

@media (max-width: 574px) {
    ul.messagelist {
        font-size: 110%;

        li {
            padding: 0.2em 0.8em;
        }
    }
}
