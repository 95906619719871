@use './../bootstrap/variables' as *;

.slick-slide {
    filter: blur(4px);
    transition: all 0.18s ease-in-out;

    @media (min-width: 768px) {
        padding: 0 20px;
    }

    > article {
        box-shadow: 2px 2px 10px 2px rgba(47, 44, 44, 0.15);
        margin: auto auto 30px;
        max-width: 400px;
        transition: all 0.18s ease-in-out;

        aside {
            padding: 25px 30px;

            @media (min-width: 768px) {
                min-height: 201px;
            }

            @media (min-width: 992px) {
                min-height: 272px;
            }

            @media (min-width: 768px) {
                min-height: 174px;
            }
        }
    }
}

.slick-center {
    filter: blur(0);
}

.control-slick-prev,
.control-slick-next {
    align-items: center;
    background-color: $warning;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 45px;
    justify-content: center;
    transition: all 0.18s ease-in-out;
    width: 45px;

    &:hover,
    &:focus {
        background-color: #FBD250;
    }

    &.bg-light {
        cursor: default;
    }
}

.control-slick-item-counter {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 35px;
}

.slick-slider {
    margin-left: -20px;
    margin-right: -20px;
}

.slick-container {
    @media (min-width: 1450px) {
        max-width: 1440px;
    }
}
