@use './../bootstrap/variables' as *;

.navbar-control {
    @media (min-width: 992px) {
        display: none;
    }
}

div.navbar-control {
    background-color: $white;
}

.navbar-content {
    background-color: $white;
    transform: translate(0, 0);
    transition: all 0.15s ease-in-out;

    @media (max-width: 991px) {
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.15);
        color: $dark;
        opacity: 0;
        position: absolute;
        transform: scaleY(0);
        transform-origin: top;
        transition: all 0.15s ease-in-out;
        width: 100%;
        z-index: 10;
    }

    @media (min-width: 992px) {
        padding: 50px 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1030;
    }

    a {
        color: $dark;
    }

    .logo {
        @media (max-width: 991px) {
            display: none;
        }
    }
}

.show-body {
    .navbar-content {
        position: fixed;

        @media (max-width: 991px) {
            opacity: 1;
            transform: translate(0, 58px) scaleY(1);
            transform-origin: top;
        }
    }

    .navbar-control {
        z-index: 100;
    }
}

#toggleMenu {
    cursor: pointer;
    display: block;
    height: 50px;
    position: relative;
    width: 50px;

    @media (min-width: 992px) {
        display: none;
    }
}

.menu-icon {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
}

.logo {
    height: 24px;
    transition: all 0.15s ease-in-out;

    @media (min-width: 992px) {
        height: 42px;
        padding-right: 2rem;
    }
}

#closeMenu {
    transform: scale(0);
    transition: all 0.15s ease-in-out;
}

#openMenu {
    transform: scale(1);
    transition: all 0.15s ease-in-out;
}

.show-body {
    #openMenu {
        transform: scale(0);
    }

    #closeMenu {
        transform: scale(1);
    }
}

.is-homepage {
    .navbar-content {
        background-color: $white;
    }

    .navbar-content {
        @media (min-width: 992px) {
            padding: 39px 0 0;
        }
    }
}

.navbar-content.page-scroll {
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.85);

    @media (min-width: 992px) {
        padding: 16px 0;
    }

    .logo {
        @media (min-width: 992px) {
            height: 24px;
        }
    }
}

.navbar-control.page-scroll .navbar-control {
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.85);
}
