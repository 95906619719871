.btn-primary {
    @include button-variant( // bg
        $warning,
        // border-color
        $warning,
        // color
        $dark,
        // hover:bg
        #FBD250,
        // hover:border-color
        #FBD250,
        // hover:color
        $dark,
        // active:bg
        #FBD250,
        // active:border-color
        #FBD250,
        // active:color
        $dark );
}

.btn-danger {
    @include button-variant( // bg
        #ba1b02,
        // border-color
        #ba1b02,
        // color
        $white,
        // hover:bg
        #cd2a10,
        // hover:border-color
        #cd2a10,
        // hover:color
        $white,
        // active:bg
        #cd2a10,
        // active:border-color
        #cd2a10,
        // active:color
        $white);
}

.btn {
    &:disabled,
    &.disabled,
    fieldset:disabled & {
        background-color: $light;
        border-color: $light;
        color: $border;
    }
}
