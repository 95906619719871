@use './../bootstrap/variables' as *;

.main-oidc-button {
    .djangocms-oidc-signup:not(.update-data) {
        background-color: $warning;
        border-radius: 5px;
        color: $dark;
        display: inline-block;
        font-size: 1.875rem;
        font-weight: 900;
        min-width: 100%;
        padding: 1.25rem;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;

        &:hover,
        &:focus {
            background-color: #fbd250;
        }

        @media (min-width: 567px) {
            min-width: 385px;
        }
    }
}

.djangocms-oidc {
    a {
        text-decoration: underline;
    }
}
